import '../../styles/pages/fall-winter-2024/presidents-message.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/fall-winter-2024/presidents-message/story6-hero-1920x1080.jpg';
import socialBanner from "../../images/fall-winter-2024/social-banners/story6-social-1200x630.jpg";
import titelGraphic from "../../images/fall-winter-2024/presidents-message/story6-title-380x245.svg";
import ctaBanner from '../../images/fall-winter-2024/presidents-message/story6-cta-1920x515.jpg';
import RonaldRochonSignature from '../../images/fall-winter-2024/presidents-message/Ronald-Rochon-Signature.svg';

// import presidentsIMG1 from '../../images/fall-winter-2024/presidents-message/story6-01-533x800.jpg';
// import presidentsIMG2 from '../../images/fall-winter-2024/presidents-message/story6-01-800x533.jpg';
// import presidentsIMG3 from '../../images/fall-winter-2024/presidents-message/story6-02-533x800.jpg';
// import presidentsIMG4 from '../../images/fall-winter-2024/presidents-message/story6-02-800x533.jpg';
// import presidentsIMG5 from '../../images/fall-winter-2024/presidents-message/story6-03-800x533.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'A Message From Cal State Fullerton President';
    var pageDesc = 'Thank you for such a warm and genuine welcome these past few months for me and my family. I’m so proud to be your 10th president.';
    var slug = 'presidents-message';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    // const photos = [
    //   {
    //     src: presidentsIMG1,
    //     width: 533,
    //     height: 800,
    //     alt: '',
    //     title: ''
    //   },
    //   {
    //     src: presidentsIMG2,
    //     width: 800,
    //     height: 533,
    //     alt: '',
    //     title: ''
    //   },
    //   {
    //     src: presidentsIMG3,
    //     width: 533,
    //     height: 800,
    //     alt: '',
    //     title: ''
    //   },
    //   {
    //     src: presidentsIMG4,
    //     width: 800,
    //     height: 533,
    //     alt: '',
    //     title: ''
    //   },
    //   {
    //     src: presidentsIMG5,
    //     width: 800,
    //     height: 533,
    //     alt: '',
    //     title: ''
    //   },
    // ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt="President's Message"/></h1>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small' style={{marginTop: '2em'}}>

          {/* <figure className='align-left large align-outside'>
      <img src={presidentsIMG1} alt="" />
      <figcaption>Caption Needed</figcaption>
</figure>
<figure className='align-left large align-outside'>
      <img src={presidentsIMG2} alt="" />
      <figcaption>Caption Needed</figcaption>
</figure>
<figure className='align-left large align-outside'>
      <img src={presidentsIMG3} alt="" />
      <figcaption>Caption Needed</figcaption>
</figure>
<figure className='align-left large align-outside'>
      <img src={presidentsIMG4} alt="" />
      <figcaption>Caption Needed</figcaption>
</figure>
<figure className='align-left large align-outside'>
      <img src={presidentsIMG5} alt="" />
      <figcaption>Caption Needed</figcaption>
</figure> */}


<p>Titan Family,</p>
<p>Thank you for such a warm and genuine welcome these past few months for me and my family. I&rsquo;m so proud to be your 10th president.</p>
<p>In my career of more than 30 years in higher education, I have been afforded some amazing opportunities to travel and participate in some wonderful communities from Chicago to Tuskegee, Texas to Washington State, Wisconsin to New York and Indiana, and now in Fullerton, California. I believe all these experiences taught me what leadership looks like and what it means to engage a community with an open heart and open mind to serve.</p>
<p>I am deeply appreciative to all my previous institutions and colleagues who have poured into me, mentored me, guided me and shown me some of the best practices when it comes to leading, engaging and building community. Now, here at this amazing institution of nearly 43,000 students and more than 4,000 faculty and staff who uplift and transform lives, I am witness to how we collaborate to produce solutions and answers to complex situations in our region and our nation.</p>
<p>Cal State Fullerton thrives because its campus culture is inviting, innovative and enriching. Our Titans build community that transcends prescribed barriers. Titans haven&rsquo;t forgotten who they are, but they have invited others to get to know who they are, and that in itself is a wonderful academic journey and blessing to me.</p>
<p>I&rsquo;m excited to be a Titan and to serve this campus. Also, I&rsquo;m excited about seeing the successes that we will create collectively as we partner with our students who identify this place as their academic home. Let us <em>all</em> continue to pour into them as others have done for us.</p>
<p>This edition of Titan magazine celebrates the many successes of our proud Titans.</p>
<p>You won&rsquo;t want to miss these impressive stories about:</p>
<ul className='marker'>
 <li>Theatre arts alum Kirsten Vangsness who plays Penelope Garcia in the hit CBS show and Paramount+ spinoff, &ldquo;Criminal Minds&rdquo;;</li>
 <li>Third-generation family member and business alum&nbsp;Joshua&nbsp;González, a leader of the Northgate Market chain; and</li>
 <li>Christine Kaford, an assistant professor of nursing, who says COVID-19 taught her to focus on gratitude and now teaches students to do the same.</li>
</ul>
<br />
<p>My hope is that each Titan&rsquo;s journey inspires you to connect or reconnect with your alma mater and serve with me to help all Titans succeed. <span className="end-mark" /></p>


<p style={{lineHeight: '1.7em'}}>
            Sincerely,<br /><br />
            <img src={RonaldRochonSignature} alt="Dr. Ronald S. Rochon" width="300px" /><br />
            Dr. Ronald S. Rochon<br />
            President<br />
            California State University, Fullerton
          </p>


          
          </div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          {/* <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the Center for Scholars</span><br />
              <a href="https://give.fullerton.edu/cfs" target='_blank' rel='noreferrer'>give.fullerton.edu/cfs</a>
            </p> 
          </div> */}
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 